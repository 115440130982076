import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash.clonedeep';

import { useSites, usePreferences, USER_PREFERENCES_QUERY_KEY } from '@braincube/header';
import { getLocaleKey, LocalesMapper } from '@braincube/i18n';
import {
    Brazil,
    France,
    Germany,
    Italy,
    Netherlands,
    RepublicOfPoland,
    Russia,
    Spain,
    Turkey,
    UnitedKingdom,
    UnitedStatesOfAmerica,
} from '@braincube/svg';

import { useMenuContext } from 'Menu/contexts';
import postLang from 'Menu/components/Lang/services';
import Toggler from 'Menu/components/Toggler';
import { modifyUserPreference } from 'Menu/services';

function LangListItem({ lang, locale, onClick }) {
    const handleClick = useCallback(() => {
        onClick(lang.locale);
    }, [lang.locale, onClick]);

    return (
        <ListItem key={lang.locale} button onClick={handleClick} selected={lang.locale === locale}>
            <ListItemIcon>{lang.icon}</ListItemIcon>
            <ListItemText primary={lang.label} />
        </ListItem>
    );
}

LangListItem.propTypes = {
    lang: PropTypes.shape({
        locale: PropTypes.string.isRequired,
        icon: PropTypes.element.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const langs = [
    { label: 'Deutsch', locale: LocalesMapper.LOCALES.DE, icon: <Germany /> },
    { label: 'English', locale: LocalesMapper.LOCALES.EN, icon: <UnitedKingdom /> },
    { label: 'English US', locale: LocalesMapper.LOCALES.EN_US, icon: <UnitedStatesOfAmerica /> },
    { label: 'Español', locale: LocalesMapper.LOCALES.ES, icon: <Spain /> },
    { label: 'Français', locale: LocalesMapper.LOCALES.FR, icon: <France /> },
    { label: 'Italiano', locale: LocalesMapper.LOCALES.IT, icon: <Italy /> },
    { label: 'Nederlands', locale: LocalesMapper.LOCALES.NL, icon: <Netherlands /> },
    { label: 'Polski', locale: LocalesMapper.LOCALES.PL, icon: <RepublicOfPoland /> },
    { label: 'Português BR', locale: LocalesMapper.LOCALES.PT_BR, icon: <Brazil /> },
    { label: 'Türkçe', locale: LocalesMapper.LOCALES.TR, icon: <Turkey /> },
    { label: 'Русский', locale: LocalesMapper.LOCALES.RU, icon: <Russia /> },
].map((lang) => ({
    ...lang,
    renderListItem: (locale, onClick) => <LangListItem key={lang.locale} lang={lang} locale={locale} onClick={onClick} />,
}));

function Lang({ reloadIframe }) {
    const {
        user: {
            general: { locale },
        },
    } = usePreferences();

    const queryClient = useQueryClient();

    const formattedLocale = getLocaleKey(locale);

    const { setIsOpen } = useMenuContext();
    const { braincubeProduct } = useSites();

    const currentLanguage = useMemo(() => {
        const found = langs.find((lang) => lang.locale === formattedLocale);

        if (found) {
            return found;
        }

        return langs.find((lang) => lang.locale === LocalesMapper.LOCALES.EN_US);
    }, [formattedLocale]);

    const { mutate: updateLang } = useMutation({
        mutationFn: ({ preference, value }) => modifyUserPreference(preference, value),
        onMutate: async ({ value }) => {
            await queryClient.cancelQueries(USER_PREFERENCES_QUERY_KEY);

            const previousCache = queryClient.getQueryData(USER_PREFERENCES_QUERY_KEY);

            queryClient.setQueryData(USER_PREFERENCES_QUERY_KEY, (old) => {
                const copy = cloneDeep(old);
                copy.settings.general.locale = value;

                return copy;
            });

            return previousCache;
        },
        onSettled: () => {
            queryClient.invalidateQueries(USER_PREFERENCES_QUERY_KEY).then(() => {
                setIsOpen(false);
            });
        },
        onError: (error, variables, previousCache) => {
            queryClient.setQueryData(USER_PREFERENCES_QUERY_KEY, previousCache);
        },
    });

    function handleClick(newLang) {
        setIsOpen(false);
        updateLang({ preference: 'locale', value: newLang.replace('-br', '-BR') });
        postLang(newLang.replace('-br', '-BR'), braincubeProduct.name);
        reloadIframe();
    }

    return (
        <Toggler icon={currentLanguage.icon} label={currentLanguage.label}>
            {langs.map((lang) => lang.renderListItem(formattedLocale, handleClick))}
        </Toggler>
    );
}

Lang.propTypes = {
    reloadIframe: PropTypes.func.isRequired,
};

export default Lang;
