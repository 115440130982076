import React, { createContext, useMemo, useState } from 'react';

const MarioContext = createContext({
    isBusy: false,
    setIsBusy: () => {},
});

function MarioContextProvider({ children }) {
    const [isBusy, setIsBusy] = useState(false);

    const value = useMemo(() => ({ isBusy, setIsBusy }), [isBusy]);

    return <MarioContext.Provider value={value}>{children}</MarioContext.Provider>;
}

export { MarioContext, MarioContextProvider };
