import React, { useCallback, useContext, useMemo } from 'react';
import {
    Avatar,
    styled,
    List,
    ListItem,
    ListItemIcon,
    Divider,
    Typography,
    ListItemText,
    ListSubheader,
    SwipeableDrawer,
    colors,
} from '@mui/material';
import { Cached, ExitToApp } from '@mui/icons-material';

import { useBraincubeProductAccess, useMe, useSites } from '@braincube/header';
import { useI18n } from '@braincube/i18n';

import AppListItem from 'Menu/components/AppListItem';
import { logout, redirectToLogin } from 'services/api';
import { IframeContext } from 'Iframe/contexts/iframeContext';
import { usePresentationModeContext } from 'Menu/components/PresentationMode/contexts';
import postPresentationMode from 'Menu/components/PresentationMode/services';
import { useMenuContext } from 'Menu/contexts';
import DisplayType from 'Menu/components/DisplayType';
import InitialAvatar from 'InitialAvatar';
import Langs from 'Menu/components/Lang';
import PresentationMode from 'Menu/components/PresentationMode';
import { clearCache, iOs } from 'Menu/services';

const StyledHeader = styled('div')(({ theme }) => ({
    height: theme.header.height,
    background: colors.grey[500],
    flexShrink: 0,
}));

const StyledDrawerContent = styled('div')({
    overflow: 'auto',
});

const StyledPersonal = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBottom: 0,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

const drawerModalProps = { keepMounted: true };

const DisplayListSubHeader = <ListSubheader disableSticky>Display</ListSubheader>;

function Menu() {
    const i18n = useI18n();
    const { presentationMode } = usePresentationModeContext();
    const { postMessage } = useContext(IframeContext);
    const { isOpen, setIsOpen } = useMenuContext();
    const { braincubeProduct } = useSites();
    const { accessList, user } = useMe();
    const braincubeProductAccess = useBraincubeProductAccess();

    const closeMenu = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    const openMenu = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);

    const reloadIframe = useCallback(() => {
        postMessage('reload');
        postMessage('clearReactQueryCache');
    }, [postMessage]);

    const isAdminProfile = useMemo(() => {
        if (!braincubeProduct) {
            return false;
        }

        const productAccessRight = accessList.find(
            (access) => access.product.productId === braincubeProduct.productId
        ).accessRight;

        return productAccessRight === 'IPLWADMIN' || productAccessRight === 'COACH' || productAccessRight === 'ADMIN';
    }, [accessList, braincubeProduct]);

    const handleLogout = useCallback(() => {
        if (presentationMode) {
            postPresentationMode(braincubeProduct.name).finally(redirectToLogin);
        } else {
            logout();
        }
    }, [braincubeProduct?.name, presentationMode]);

    const handleClearCache = useCallback(() => {
        closeMenu();
        clearCache(braincubeProduct.name);
        reloadIframe();
    }, [braincubeProduct?.name, closeMenu, reloadIframe]);

    return (
        <SwipeableDrawer
            anchor="right"
            open={isOpen}
            onClose={closeMenu}
            onOpen={openMenu}
            ModalProps={drawerModalProps}
            disableBackdropTransition={!iOs}
            disableDiscovery={iOs}
            disableSwipeToOpen
        >
            <StyledHeader />
            <StyledDrawerContent>
                <StyledPersonal>
                    <InitialAvatar avatar={StyledAvatar} />
                    <Typography variant="h6">{`${user.firstName} ${user.lastName}`}</Typography>
                    <Typography>{user.email}</Typography>
                    {braincubeProduct && (
                        <Typography gutterBottom variant="overline">
                            {braincubeProductAccess.accessRight}
                        </Typography>
                    )}
                </StyledPersonal>
                <List disablePadding>
                    <AppListItem pkgName="@braincube/account" />
                    {isAdminProfile && <AppListItem pkgName="@braincube/administration" />}
                </List>

                {braincubeProduct && (
                    <>
                        <Divider />
                        <List disablePadding subheader={DisplayListSubHeader}>
                            <DisplayType reloadIframe={reloadIframe} />

                            <Langs reloadIframe={reloadIframe} />

                            <PresentationMode />

                            {!presentationMode && (
                                <ListItem button onClick={handleClearCache}>
                                    <ListItemIcon>
                                        <Cached />
                                    </ListItemIcon>
                                    <ListItemText primary={i18n.tc('menu.clearCache')} />
                                </ListItem>
                            )}
                        </List>

                        <Divider />

                        <List disablePadding>
                            <ListItem button onClick={handleLogout}>
                                <ListItemIcon>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText primary={i18n.tc('menu.exit')} />
                            </ListItem>
                        </List>
                    </>
                )}
            </StyledDrawerContent>
        </SwipeableDrawer>
    );
}

export default Menu;
